let DateTime = require('luxon').DateTime;
const entryValid = require('../sheet/day/entry/entryValid');

module.exports = function (startDate, endDate, weekDayExceptions, entry) {
  if (!entry) return [];
  weekDayExceptions = weekDayExceptions || [];

  let entries = [];
  let current = DateTime.fromISO(startDate);
  let end = DateTime.fromISO(endDate || startDate);

  while (current <= end) {
    if (weekDayExceptions.indexOf(current.weekday) < 0) {
      let copy = JSON.parse(JSON.stringify(entry));
      copy.date = current.toISODate();
      copy.valid = entryValid(entry);
      entries.push(copy);
    }
    current = current.plus({day: 1});
  }
  return entries;
};
