<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-4 py-2" v-if="company && isManagerRW">
      <div class="">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'planning', params: { cid } }"><a>{{ $t('breadcrumb_company_planning') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>

    <section class="bg-white border-t pt-2">
      <div class="flex justify-between">
        <div class="flex items-center">
          <sqr-button
            label="planning_current"
            class="ml-2"
            @click="$refs.cal.getApi().gotoDate(new Date())"
          />
          <sqr-button
            icon="chevron-left"
            class="ml-2"
            @click="$refs.cal.getApi().prev()"
          />
          <sqr-button
            icon="chevron-right"
            class="ml-1"
            @click="$refs.cal.getApi().next()"
          />
          <div class="ml-4 font-medium text-xl">{{ from | month }}</div>
          <div class="ml-4" v-if="isManager">
            <label class="checkbox">
              <input type="checkbox" v-model="retro" @change="refresh()" />
              {{ $t('planning_retro') }}
            </label>
          </div>
        </div>
        <div class="hidden md:flex items-center">
          <sqr-router-link
            label="planning_org"
            :to="{ name: 'org', params: { cid, oid: 'default' } }"
            class="mr-2"
            v-if="isManager"
          />
          <sqr-router-link
            label="planning_holidays"
            :to="{ name: 'company-holidays', params: { cid, oid: 'default' } }"
            class="mr-2"
            v-if="isManager"
          />
        </div>
      </div>
    </section>
    <sqr-progress
      class="invisible h-2 overflow-hidden"
      :class="{ visible: true || orgLoading || planLoading }"
    />

    <sqr-error :error="orgLoadError" />

    <section class="px-2 md:px-0 py-0" v-if="!orgLoadError">
      <div class="">
        <full-calendar
          ref="cal"
          :header="false"
          default-view="resourceTimelineMonth"
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          :week-numbers="true"
          week-number-title="WW"
          week-number-calculation="ISO"
          :plugins="plugins"
          :resource-label-text="$t('planning_accounts')"
          :resource-area-width="230"
          :resources="resources"
          :resource-text="resourceText"
          :events="events"
          :business-hours="{ daysOfWeek: [1, 2, 3, 4, 5] }"
          :language="locale"
          :first-day="1"
          :editable="true"
          @eventClick="eventClick"
          :aspect-ratio="1.8"
          content-height="auto"
          :selectable="true"
          @select="select"
          @datesRender="datesRender"
          :eventRender="eventRender"
        />
      </div>
    </section>

    <event-modal ref="eventModal" />
  </main>
</template>

<style lang="scss">
// current issue https://github.com/FullHuman/purgecss/issues/170
/*! purgecss start ignore */
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';
/*! purgecss end ignore */
</style>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { fromPairs, flatten } from 'ramda';
import company from './company';
import FullCalendar from '@fullcalendar/vue';
import timeline from '@fullcalendar/resource-timeline';
import interaction from '@fullcalendar/interaction';

import month from '@/filters/month';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import SqrButton from '@/sqrd/SqrButton';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

import CompanyName from '@/components/CompanyName';
import EventModal from '@/components/EventModal';

import textColor from '@/utils/textColor';
import shadeColor from '@/utils/shadeColor';

import { DateTime } from 'luxon';

// import SqrInputText from '@/sqrd/SqrInputText';
// import UpdateInfo from '@/components/UpdateInfo';

import { dom } from '@fortawesome/fontawesome-svg-core';

export default {
  name: 'Planning',
  mixins: [company],
  filters: { month },
  components: {
    FullCalendar,
    SqrProgress,
    SqrError,
    SqrButton,
    SqrRouterLink,
    CompanyName,
    EventModal,
  },
  computed: {
    ...mapGetters('perms', ['isManager', 'isManagerRW', 'isAdmin']),
    locale() {
      return Vue.$locale.locale;
    },
    ...mapState('org', {
      org: 'record',
      orgLoading: 'loading',
      orgLoadError: 'loadError',
    }),
    ...mapState('planning', { eventsByAid: 'docs' }),
    planning() {
      return flatten(Object.values(this.eventsByAid));
    },
    resources() {
      return this.org?.content ?? [];
    },
    events() {
      return this.retro ? this.eventsFromSheets : this.eventsFromCalendar;
    },
    eventsFromCalendar() {
      const locale = this.locale;
      return this.planning.map(e => {
        let entry =
          (e.entries && e.entries.length > 0 && e.entries[0]) || e.entry;
        let title = entry
          ? (entry.name18 && entry.name18[locale]) || entry.name
          : '';

        let color = entry ? entry.color : '#000000';

        if (e.status === 'accepted') {
          title = title + ' (V)';
        } else {
          title = '? ' + title;
          color = shadeColor(color, 80);
        }

        let note = '';
        if (e.note) note += e.note.split(/\n/)[0];
        note = note.trim();
        if (note) {
          title += ' | ' + note;
        }

        return {
          ...event,
          resourceId: e.accountId,
          start: DateTime.fromISO(e.startDate).toISODate(),
          end: DateTime.fromISO(e.endDate).plus({ day: 1 }).toISODate(),
          title: title,
          color: color,
          textColor: textColor(color),
          original: e,
          resourceEditable: false,
          editable: false,
        };
      });
    },
    ...mapState('companyReportSheets', { reports: 'docs' }),
    eventsFromSheets() {
      const sheetEntries = Object.entries(this.reports).map(
        ([accountId, report]) => {
          const sheets = Object.values(report?.sheetsById ?? {});
          return sheets.map(sheet =>
            Object.entries(sheet?.timeEntriesById ?? {}).map(([id, entry]) => ({
              ...entry,
              accountId,
              id,
            }))
          );
        }
      );
      const entries = flatten(sheetEntries);

      const locale = this.locale;

      const events = entries.map(entry => {
        let title = entry
          ? (entry.name18 && entry.name18[locale]) || entry.name
          : '';
        let color = entry ? entry.color : '#000000';
        let note = '';
        if (entry.note) note += entry.note.split(/\n/)[0];
        note = note.trim();
        if (note) {
          title += ' | ' + note;
        }

        return {
          resourceId: entry.accountId,
          start: DateTime.fromISO(entry.date).toISODate(),
          end: DateTime.fromISO(entry.date).plus({ day: 1 }).toISODate(),
          title: title,
          color: color,
          textColor: textColor(color),
          resourceEditable: false,
          editable: false,
        };
      });

      return events;
    },
  },
  data() {
    return {
      plugins: [timeline, interaction],
      from: '',
      retro: false,
      event: null,
    };
  },
  mounted() {
    // this.load();
    dom.watch();
  },
  methods: {
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('planning', { planSub: 'sub' }),
    ...mapActions('company', ['fieldSet']),
    ...mapActions('event', ['init', 'sub']),
    ...mapActions('companyReportSheets', { reportSub: 'sub' }),
    async load(month) {
      const org = await this.orgSub({
        path: ['companies', this.cid, 'orgs'],
        id: 'default',
      });
      if (org?.content) {
        const aids = org.content
          .filter(entry => entry.type === 'account')
          .map(entry => entry.id);

        if (!this.retro) {
          const pathes = fromPairs(
            aids.map(aid => [
              aid,
              ['companies', this.cid, 'accounts', aid, 'events'],
            ])
          );
          const where = [['months', 'array-contains', month]];
          this.planSub({ pathes, where });
        }

        if (this.retro) {
          const year = month.substr(0, 4);
          const reportPathes = fromPairs(
            aids.map(aid => [
              aid,
              [
                'companies',
                this.cid,
                'accounts',
                aid,
                'reports',
                `${year}-sheets`,
              ],
            ])
          );
          this.reportSub({ pathes: reportPathes });
        }
      }
    },
    eventRender({ event, el }) {
      const ev = event?.extendedProps?.original;
      if (ev?.entries?.length > 0) {
        const entry = ev.entries[0];
        const icon = entry.icon;
        const iconEl = document.createElement('i');
        iconEl.classList.add('fal');
        iconEl.classList.add('fa-' + icon);
        iconEl.classList.add('mr-1');
        el.querySelector('.fc-title').prepend(iconEl);
      }
    },
    resourceText(item) {
      const props = item?.extendedProps ?? {};
      return props.name ?? `${props.givenName} ${props.familyName}`;
    },
    datesRender({ view }) {
      const from = DateTime.fromJSDate(view.activeStart).toISODate();
      // const endDate = DateTime.fromJSDate(view.activeEnd).toISODate()
      this.from = from;
      const month = from.substring(0, 7);
      this.load(month);
    },
    refresh() {
      const view = this.$refs.cal.getApi().view;
      if (!view) return;
      this.datesRender({ view });
    },
    eventClick(info) {
      const event = info?.event?.extendedProps?.original;
      const id = event?.id;
      if (event && id) {
        this.sub({
          path: [
            'companies',
            event.companyId,
            'accounts',
            event.accountId,
            'events',
          ],
          id,
        });
        // this is not perfect
        this.$refs.eventModal.allAccounts = false;
      }
    },
    select(info) {
      const startDate = DateTime.fromJSDate(info.start).toISODate();
      const endDate = DateTime.fromJSDate(info.end)
        .minus({ day: 1 })
        .toISODate();
      const accountId = info.resource.id;
      const account = this.org?.content?.find(entry => entry.id === accountId);
      if (!account) return; // NOTE one day maybe, create for all team members
      const { givenName, familyName } = account;
      this.init({
        record: {
          accountId,
          givenName,
          familyName,
          startDate,
          endDate,
          weekDayExceptions: [6, 7],
          status: 'pending',
        },
      });
      this.$refs.cal.getApi().unselect();
    },
  },
};
</script>
