<template>
  <button
    class="relative"
    :class="[size, ...buttonClass]"
    :type="type"
    v-bind="$attrs"
    @click="handleClick"
  >
    <!-- v-on="$listeners" -->
    <slot>
      <div :class="{ invisible: loading }">
        <span v-if="icon"><fa :icon="[iconPrefix, icon]" /></span>
        <span v-if="icon && label" class="px-1"></span>
        <span v-if="label">{{ label }}</span>
      </div>
    </slot>
    <slot name="loading">
      <div
        class="absolute top-0 py-2 left-0 right-0 text-center"
        v-show="loading"
      >
        <fa :icon="[iconPrefix, 'spinner']" spin size="lg" />
      </div>
    </slot>
  </button>
</template>

<style lang="postcss" scoped>
button {
  @apply py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md transition duration-150 ease-in-out;
}
button.xs {
  @apply px-2.5 py-1.5 border border-transparent text-xs leading-4 rounded;
}
button.sm {
  @apply px-3 py-2 border border-transparent text-sm leading-4 rounded-md;
}
button.md {
  @apply px-4 py-2 border border-transparent text-sm leading-5 rounded-md;
}
button.lg {
  @apply px-4 py-2 border border-transparent text-base leading-6 rounded-md;
}
button.xl {
  @apply px-6 py-3 border border-transparent text-base leading-6 rounded-md;
}
</style>

<script>
export default {
  name: 'SqrButton',
  props: {
    type: { type: String, default: 'button' },
    label: String,
    color: { type: String, default: 'blue' },
    second: { type: String, default: 'blue' },
    light: { type: Boolean },
    size: { type: String, default: 'md' },
    icon: String,
    iconPrefix: {
      type: String,
      default: process.env.VUE_APP_ICON_PREFIX || 'far',
    },
    loading: { type: Boolean },
  },
  computed: {
    buttonClass() {
      let color = this.color;
      let light = this.light;
      let second = this.second;
      switch (color) {
        case 'primary':
          color = 'blue';
          break;
        case 'light':
          color = 'gray';
          light = true;
          break;
      }
      let base = [];
      if (this.$attrs.disabled) {
        base.push('cursor-not-allowed');
        base.push('opacity-50');
      }
      if (color === 'transparent') {
        return [
          ...base,
          `text-black`,
          `shadow-none`,
          `hover:bg-${second}-500`,
          `hover:text-${second}-100`,
          `focus:border-${second}-300`,
          `focus:outline-none`,
          `focus:shadow-outline-${second}`,
          `active:bg-${second}-600`,
          `active:text-${second}-100`,
        ];
      }
      if (color === 'white') {
        return [
          ...base,
          `text-black`,
          `bg-white`,
          `shadow-none`,
          `hover:bg-${second}-50`,
          `focus:border-${second}-300`,
          `focus:outline-none`,
          `focus:shadow-outline-${second}`,
          `active:bg-${second}-600`,
          `active:text-white`,
        ];
      } else if (color === 'black') {
        return [
          ...base,
          'text-white',
          `bg-black`,
          `hover:bg-${second}-500`,
          `focus:outline-none`,
          `focus:shadow-outline-${second}`,
          `active:bg-${second}-600`,
        ];
      } else if (light) {
        return [
          ...base,
          `text-${color}-700`,
          `bg-${color}-50`,
          `shadow-none`,
          `hover:text-${second}-700`,
          `hover:bg-${second}-50`,
          `focus:border-${second}-300`,
          `focus:outline-none`,
          `focus:shadow-outline-${second}`,
          `active:bg-${second}-600`,
          `active:text-white`,
        ];
      } else {
        return [
          ...base,
          'text-white',
          `bg-${color}-600`,
          'shadow-sm',
          `hover:bg-${color}-500`,
          `focus:outline-none`,
          `focus:shadow-outline-${color}`,
          `active:bg-${color}-600`,
        ];
      }
    },
  },
  methods: {
    handleClick(event) {
      if (this.$attrs.disabled || this.loading) {
        event.preventDefault();
      } else {
        this.$emit('click', event);
      }
    },
  },
};
</script>
