import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import textColor from '@/utils/textColor';
import stamp from '@/utils/stamp';

import weekDayLong from '@/filters/weekDayLong';
import week from '@/filters/week';
import name from '@/filters/name';

import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrInputDisplay from '@/sqrd/SqrInputDisplay';
import SqrInputDate from '@/sqrd/SqrInputDate';
import SqrInputTextarea from '@/sqrd/SqrInputTextarea';
import SqrInputCheckbox from '@/sqrd/SqrInputCheckbox';
import SqrButton from '@/sqrd/SqrButton';
import SqrFireFiles from '@/sqrd/SqrFireFiles';

import WeekDayEntry from '@/components/WeekDayEntry';
import AccountPicker from '@/components/AccountPicker';
import EntryModelModal from '@/components/EntryModelModal';
import StatusButtons from '@/components/StatusButtons';
import StatusMessage from '@/components/StatusMessage';

import generate from 'sqr-wotime-core/events/generateEventEntries';
import merge from 'sqr-wotime-core/events/mergeEventEntries';
import entryValid from 'sqr-wotime-core/sheet/day/entry/entryValid';
import isoMonths from 'sqr-wotime-core/dates/months';
import isoWeeks from 'sqr-wotime-core/dates/weeks';
import { fromPairs } from 'ramda';
import { DateTime } from 'luxon';

import nanourl from '@/utils/nanourl';

function eventName18(event) {
  const entry = event?.entries?.[0];
  const pairs = ['en', 'de', 'fr'].map(lang => {
    const entryName = entry?.name18?.[lang] ?? '';
    const intervalName =
      event.startDate === event.endDate
        ? DateTime.fromISO(event.startDate)
            .setLocale(lang)
            .toLocaleString(DateTime.DATE_MED)
        : [
            DateTime.fromISO(event.startDate)
              .setLocale(lang)
              .toLocaleString(DateTime.DATE_MED),
            DateTime.fromISO(event.endDate)
              .setLocale(lang)
              .toLocaleString(DateTime.DATE_MED)
          ].join(' - ');
    const name = `${entryName} ${intervalName}`;
    return [lang, name];
  });
  return fromPairs(pairs);
}

export default {
  mixins: [validationMixin],
  filters: {
    weekDayLong,
    week,
    name
  },
  components: {
    SqrErrorNote,
    SqrProgress,
    SqrInputCheckbox,
    SqrInputDisplay,
    SqrInputDate,
    SqrInputTextarea,
    SqrButton,
    SqrFireFiles,

    AccountPicker,
    EntryModelModal,
    WeekDayEntry,
    StatusButtons,
    StatusMessage
  },
  data() {
    return {
      allAccounts: false,
      accountPick: false,
      entryModelPick: false,
      statusChanged: false
    };
  },
  computed: {
    ...mapState('company', { company: 'record', companyId: 'id' }),
    ...mapState('org', { org: 'record' }), // used for multiple create
    ...mapState('event', { event: 'record' }),
    ...mapState('event', [
      'loading',
      'loadError',
      'saving',
      'saveError',
      'removing',
      'removeError'
    ]),
    ...mapGetters('perms', [
      'isManager',
      'isManagerRW',
      'isEmployee',
      'isEmployeeRW'
    ]),
    ...mapGetters('auth', ['uid', 'displayName']),
    weekDayExceptions: {
      get() {
        return this.event?.weekDayExceptions ?? [];
      },
      set(values) {
        this.fieldSetGen({ field: 'weekDayExceptions', value: values });
      }
    },
    modalClass() {
      return {
        'is-active': this.loading || this.event || this.loadError
      };
    },
    accountDisabled() {
      return !this.isManagerRW;
    },
    disabled() {
      const isEmployeeRecord =
        this.isEmployeeRW && this.uid === this.event?.accountId;
      const canEmployeeEdit = isEmployeeRecord;
      const canEdit =
        this.isManagerRW ||
        (canEmployeeEdit && this.event?.status === 'pending');
      return !canEdit;
    },
    entry() {
      return this.event?.entries?.[0];
    },
    entryStyle() {
      const color = this.entry?.color;
      if (!color) return {};
      return {
        'background-color': color,
        color: textColor(color)
      };
    },
    attachmentsPrefix() {
      const cid = this.cid;
      const aid = this.aid;
      const prefix = new Date().getTime();
      return ['/companies', cid, 'accounts', aid, 'events', prefix].join('/');
    }
  },
  validations() {
    const accountId = this.createForAllAccounts ? {} : { required };
    return {
      event: { accountId, startDate: { required }, entryModelId: { required } }
    };
  },
  methods: {
    accountSelect(account) {
      this.fieldSet({ field: 'accountId', value: account.id });
      this.fieldSet({ field: 'familyName', value: account.familyName });
      this.fieldSet({ field: 'givenName', value: account.givenName });
      this.fieldSet({ field: 'email', value: account.email });
      this.accountPick = false;
    },
    fieldSetGen({ field, value }) {
      this.fieldSet({ field, value });
      this.regenerate();
    },
    indexes() {
      this.fieldSet({
        field: 'months',
        value: isoMonths(this.event.startDate, this.event.endDate)
      });
      this.fieldSet({
        field: 'weeks',
        value: isoWeeks(this.event.startDate, this.event.endDate)
      });
      const name18 = eventName18(this.event);
      this.fieldSet({ field: 'name18', value: name18 });
      this.fieldSet({ field: 'name', value: name18['en'] });
    },
    regenerate() {
      const modelId = this.event?.entryModelId;
      const model = this.company?.entryModels?.find(m => m.id === modelId);
      const event = this.event;
      if (model && event) {
        const entriesOld = event.entries || [];
        const entriesNew = generate(
          event.startDate,
          event.endDate,
          event.weekDayExceptions,
          model
        );
        const value = merge(
          event.startDate,
          event.endDate,
          entriesOld,
          entriesNew
        );
        this.fieldSet({ field: 'entries', value });
      }
    },
    ...mapMutations('event', ['fieldSet']),
    ...mapActions('event', ['init', 'load', 'unsub', 'save', 'remove']),
    entryChange({ date, field, value }) {
      const entries = JSON.parse(JSON.stringify(this.event.entries ?? []));
      const entry = entries.find(entry => entry.date === date);
      if (entry) {
        this.$set(entry, field, value);
        this.$set(entry, 'valid', entryValid(entry));
        this.fieldSet({ field: 'entries', value: entries });
      }
    },
    statusSet(status) {
      this.fieldSet({ field: 'status', value: status });
      const updater = stamp();
      this.fieldSet({
        field: 'accepted',
        value: status === 'accepted' ? updater : undefined
      });
      this.fieldSet({
        field: 'denied',
        value: status === 'denied' ? updater : undefined
      });
      this.statusChanged = true;
    },
    ...mapMutations('event', ['storeSet']),
    async removeAndGo() {
      try {
        await this.remove();
        this.$router.back();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    ...mapActions('moderate', ['email']),
    async validateAndSave(back) {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        this.indexes();
        const cid = this.companyId;
        const aid = this.event.accountId;
        this.fieldSet({ field: 'companyId', value: cid });
        this.storeSet({
          field: 'path',
          value: ['companies', cid, 'accounts', aid, 'events']
        });
        const { id } = await this.save();
        if (this.statusChanged) {
          this.email({
            type: 'event',
            id,
            record: this.event,
            status: this.event.status
          });
        }
        if (back) this.$router.back();
        this.unsub({ reset: true });
      }
    },
    async validateAndCreateAll(back) {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        await this.createForAllAccounts();
        if (back) this.$router.back();
        this.unsub({ reset: true });
      }
    },
    async createForAllAccounts() {
      this.indexes();
      const batch = this.$db().batch();
      const accounts = this.org?.content ?? [];
      const cid = this.companyId;
      const id = nanourl(10);
      accounts.forEach(account => {
        const event = {
          ...this.event,
          companyId: cid,
          accountId: account.id,
          familyName: account.familyName ?? '',
          givenName: account.givenName ?? '',
          email: account.email ?? '',
          commonId: id
        };
        const path = `companies/${cid}/accounts/${account.id}/events/${id}`;
        const ref = this.$db().doc(path);
        batch.set(ref, event);
      });
      await batch.commit();
    },
    async removeAllConfirm() {
      const res = confirm(
        this.$t('event_remove_all_confirm', { name: name(this.event) })
      );
      if (res) {
        this.removeAll();
      }
    },
    async removeAll() {
      const batch = this.$db().batch();
      const cid = this.companyId;
      const eid = this.eid;
      const accounts = this.org?.content ?? [];
      accounts.forEach(({ id }) => {
        const path = `companies/${cid}/accounts/${id}/events/${eid}`;
        const ref = this.$db().doc(path);
        batch.delete(ref);
      });
      await batch.commit();
    }
  }
};
