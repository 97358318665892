<template>
  <div
    class="field is-relative"
    @drop="drop"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
  >
    <div
      v-if="dragging"
      class="box is-shadowless is-overlay"
      :class="dndZoneClass"
      style="border: 1px dashed"
    >
      {{ $t('uploader_dnd_drop_here') }}
    </div>

    <div>
      <label class="label" :class="labelClass" v-if="labelTr">
        {{ labelTr }}
      </label>
      <div class="is-shadowless border rounded">
        <div class="field">
          <div
            class="is-clearfix border-b last:border-b-0 hover:bg-gray-200"
            v-for="file in value"
            :key="file.name"
          >
            <div class="flex justify-center py-2 mx-2 text-black">
              <a class="flex flex-1" @click="open(file)">
                <span class="mr-2"
                  ><fa :icon="['fal', 'file']" fixed-width
                /></span>
                <span class="flex-1">{{ file.name }}</span>
              </a>
              <div class="-mt-1 mr-2">
                <sqr-button
                  icon="trash"
                  size="small"
                  @click="remove(file)"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
          <sqr-button
            v-if="!uploading && !disabled"
            :icon="icon"
            @click.native="selectFile"
            :label-raw="placeholderTr"
            :color="color"
            class="is-fullwidth is-borderless is-radiusless"
            is-fullwidth
          />
        </div>
      </div>
    </div>

    <div>
      <div class="field">
        <!-- <label class="label" v-if="labelTr">{{ labelTr }}</label> -->
        <div class="help" v-if="dndSupport && !disabled">
          {{ $t('sqr_fire_upload_dnd_available') }}
        </div>
      </div>
      <input
        id="files"
        type="file"
        name="file"
        ref="uploadInput"
        :multiple="true"
        @change="uploadFiles($event)"
      />
      <div class="field" v-if="filesCount > 1">
        <label class="label">{{ $t('uploader_files') }}</label>
        <sqr-progress :value="fileNum" :max="filesCount" color="primary" />
      </div>
      <div class="field" v-if="uploading">
        <label class="label">{{ $t('uploader_upload') }}</label>
        <sqr-progress
          :value="bytesTransferred"
          :max="totalBytes"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<style>
.progress-bar {
  margin: 10px 0;
}
input[type='file'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

<script>
import label from './mixins/label';
import color from './mixins/color';
import placeholder from './mixins/placeholder';

import SqrButton from './SqrButton';
import SqrProgress from './SqrProgress';

export default {
  name: 'SqrFireUpload',
  components: { SqrButton, SqrProgress },
  mixins: [label, color, placeholder],
  props: {
    value: { type: Array },

    prefix: { type: String, required: true },
    icon: { type: String },
    color: { type: String },

    disabled: Boolean,
  },
  computed: {
    dndSupport() {
      var div = document.createElement('div');
      return (
        ('FormData' in window &&
          'FileReader' in window &&
          'draggable' in div) ||
        ('ondragstart' in div && 'ondrop' in div)
      );
    },
    dndZoneClass() {
      return {
        'has-background-success': this.draggingOver,
      };
    },
  },
  data() {
    return {
      dragging: false,
      draggingOver: false,

      uploading: false,
      deleting: false,
      bytesTransferred: null,
      totalBytes: 0,
      uploadTask: '',

      fileNum: 0,
      filesCount: 0,
    };
  },
  mounted() {
    window.addEventListener('dragstart', this.winDragStart, true);
    window.addEventListener('dragenter', this.winDragEnter, true);
    window.addEventListener('dragover', this.winDragOver, true);
    window.addEventListener('dragend', this.winDragEnd, true);
    window.addEventListener('dragleave', this.winDragLeave, true);
    window.addEventListener('drop', this.winDrop, true);
  },
  beforeDestroy() {
    window.removeEventListener('dragstart', this.winDragStart, true);
    window.removeEventListener('dragenter', this.winDragEnter, true);
    window.removeEventListener('dragover', this.winDragOver, true);
    window.removeEventListener('dragend', this.winDragEnd, true);
    window.removeEventListener('dragleave', this.winDragLeave, true);
    window.removeEventListener('drop', this.winDrop, true);
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    async uploadFiles(e) {
      this.uploading = true;
      let fileList = e.target.files || e.dataTransfer.files;
      this.fileNum = 1;
      this.filesCount = fileList.length;
      const uploads = Array.from(Array(fileList.length).keys()).map(async x => {
        const result = await this.upload(fileList[x]);
        this.fileNum = this.fileNum + 1;
        return result;
      });
      const results = await Promise.all(uploads);
      this.$emit('files-uploaded', results);
      this.fileNum = 0;
      this.filesCount = 0;
      this.uploading = false;
    },
    async upload(file) {
      this.fileName = file.name;
      // this.uploading = true;
      const uploadTask = this.$fb()
        .storage()
        .ref(this.prefix + file.name)
        .put(file);
      uploadTask.on('state_changed', sp => {
        this.bytesTransferred = sp.bytesTransferred;
        this.totalBytes = sp.totalBytes;
      });
      this.uploadTask = uploadTask;
      const uploaded = await uploadTask;
      // const downloadURL = await uploaded.ref.getDownloadURL();
      const result = {
        contentType: uploaded.metadata.contentType,
        fullPath: uploaded.metadata.fullPath,
        nameAlt: uploaded.metadata.name,
        name: file.name,
        size: uploaded.metadata.size,
        // downloadURL
      };
      this.$emit('file-uploaded', result);
      const files = this.value ?? [];
      this.$emit('change', [...files, result]);
      // this.uploading = false;
      return result;
    },
    async remove(file) {
      const files = [...(this.value ?? [])];
      const res = files.filter(f => f.fullPath !== file.fullPath);
      this.$emit('change', res);
    },
    async deleteFirebase(file) {
      try {
        await this.$fb().storage().ref(file.fullPath).delete();
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          // console.error('file delete error occured', error);
          return Promise.reject(error);
        }
      }
    },
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      // console.log('drop', event);
      // const files = event.dataTransfer.files;
      if (this.disabled) return;
      this.uploadFiles(event);
    },
    dragEnter() {
      // console.log('dragEnter', event);
      if (this.disabled) return;
      this.draggingOver = true;
    },
    dragLeave() {
      // console.log('dragLeave', event);
      this.draggingOver = false;
    },
    winDragEnter() {
      // event.stopPropagation();
      event.preventDefault();
      if (this.disabled) return;
      this.dragging = true;
      // console.log('winDragEnter', event);
    },
    winDragStart(event) {
      // event.stopPropagation();
      event.preventDefault();
      if (this.disabled) return;
      this.dragging = true;
      // console.log('winDragStart', event);
    },
    winDragOver(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.disabled) return;
      this.dragging = true;
      // console.log('winDragOver', event);
    },
    winDragLeave(event) {
      // event.stopPropagation();
      event.preventDefault();
      if (event.screenX === 0 && event.screenY === 0) {
        this.dragging = false;
      }
      // console.log('winDragLeave', event);
    },
    winDragExit(event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDragExit', event);
    },
    winDragEnd(event) {
      event.stopPropagation();
      event.preventDefault();
      // console.log('winDragEnd', event);
    },
    winDrop(event) {
      // event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      // console.log('winDrop', event);
    },
    async open(file) {
      try {
        const ref = this.$fb().storage().ref(file.fullPath);
        const url = await ref.getDownloadURL();
        window.open(url);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
