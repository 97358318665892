<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="control" :class="controlClass">
      <div
        class="input"
        :class="inputClass"
        v-on="listeners"
        :data-tooltip="value"
        :disabled="disabled"
      >
        <slot>
          <span>{{ value }}</span>
        </slot>
        <span class="has-text-grey-light" v-show="placeholderShow">{{
          placeholderTr
        }}</span>
      </div>
      <span class="icon is-small is-left" v-if="icon"
        ><i class="fa" :class="iconClass"></i
      ></span>
      <!-- <span class="icon is-small is-right" v-if="valid"><i class="fal fa-check"></i></span> -->
    </div>
    <p class="help" v-if="helper">{{ helperTr }}</p>
  </div>
</template>

<style scoped>
.input {
  overflow: hidden;
}
</style>

<script>
import input from './mixins/input';

export default {
  name: 'sqr-input-display',
  mixins: [input],
  computed: {
    placeholderShow() {
      return typeof this.value === 'undefined';
    },
  },
};
</script>
