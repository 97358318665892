const { DateTime } = require('luxon');

let durationRegexp = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?|P$/;

module.exports = function (entry) {
  if (!entry) return false;

  if (entry.input === 'from-until') {
    const from = DateTime.fromISO(entry.from);
    const until = DateTime.fromISO(entry.until);
    return from.isValid && until.isValid;
  } else if (entry.input === 'duration') {
    if (!entry.duration) return false;
    return durationRegexp.test(entry.duration);
  } else if (entry.input === 'day') {
    return typeof entry.day === 'number';
  } else if (entry.input === 'none') {
    return true;
  } else {
    return false;
  }
};
