<template>
  <div class="fixed z-30 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <transition
        enter-class="ease-out duration-300"
        enter-active-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="ease-in duration-200"
        leave-active-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;

      <transition
        enter-class="ease-out duration-300"
        enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-class="ease-in duration-200"
        leave-active-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 sm:my-8 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div>
              <div class="flex justify-between items-center pb-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t('account_pick_title') }}
                </h3>
                <sqr-button
                  icon="times"
                  color="white"
                  @click="$emit('close')"
                />
              </div>

              <div class="mt-2">
                <div
                  class="relative max-h-96 overflow-y-scroll scrolling-auto -mx-4 border-t"
                  v-if="record"
                >
                  <div
                    v-for="item in record.content"
                    :key="item.id"
                    :class="{ 'sticky top-0': item.type === 'team' }"
                  >
                    <p
                      class="px-4 font-semibold py-2 text-gray-800 border-b bg-gray-100"
                      v-if="item.type === 'team'"
                    >
                      {{ item.name }}
                    </p>
                    <a
                      v-if="item.type === 'account'"
                      class="block px-4 py-2 border-b text-cool-gray-700 hover:text-blue-600 hover:bg-blue-50"
                      @click="$emit('select', item)"
                    >
                      <span class="font-medium">{{ item.familyName }}</span>
                      <span>
                        {{ item.givenName }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <span class="flex w-full rounded-md shadow-sm">
              <button
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                @click="$emit('close')"
              >
                {{ $t('buttons_cancel') }}
              </button>
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SqrButton from '@/sqrd-ui/SqrButton';
export default {
  main: 'AccountPicker',
  components: { SqrButton },
  computed: {
    ...mapState('org', ['record']),
  },
};
</script>
