const { DateTime } = require('luxon');
const { flatten, uniq } = require('ramda');

module.exports = function(isoFrom, isoUntil) {
  const from = DateTime.fromISO(isoFrom);
  const until = DateTime.fromISO(isoUntil || isoFrom);
  const monthInterval = from.until(until);
  const monthIntervals = monthInterval.splitBy({ month: 1 });
  const edges = flatten(
    monthIntervals.map(interval => [interval.start, interval.end])
  );
  const dates = [from, ...edges, until];
  const months = dates
    .map(date => date.toISODate())
    .map(iso => iso.replace(/-\d+$/, ''));
  return uniq(months);
};
