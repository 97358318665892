<template>
  <div class="buttons has-addons">
    <button
      v-for="opt in opts"
      :key="opt.status"
      class="button"
      :class="{ ...opt.class, ...sizeClass }"
      @click="changed(opt.status)"
      :disabled="disabled"
    >
      {{ $t(opt.label) }}
    </button>
  </div>
</template>

<script>
import size from '../sqrd/mixins/size';
export default {
  name: 'StatusButtons',
  mixins: [size],
  props: {
    status: String,
    options: { type: Array, default: () => ['pending', 'accepted', 'denied'] },
    isLoading: Boolean,
    disabled: Boolean,
  },
  computed: {
    opts() {
      const current = this.status;
      return this.options.map(status => {
        const isCurrent = status === current;
        let clazz = {};
        if (isCurrent) {
          switch (current) {
            case 'accepted':
              clazz['is-success'] = true;
              break;
            case 'denied':
              clazz['is-danger'] = true;
              break;
            default:
              clazz['is-light'] = true;
              break;
          }
        }
        if (this.isLoading) {
          clazz['is-loading'] = true;
        }
        return {
          label: 'statuses_' + status + (isCurrent ? '' : '_select'),
          class: clazz,
          status,
        };
      });
    },
  },
  methods: {
    changed(status) {
      if (this.disabled) return;
      this.$emit('change', status);
    },
  },
};
</script>
