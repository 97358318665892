<template>
  <div :class="modalClass" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('event_title') }}
        </p>
        <button
          class="delete"
          @click="unsub({ reset: true })"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body" v-if="loading">
        <sqr-progress />
      </section>
      <section class="modal-card-body" v-else-if="loadError">
        <sqr-error-note :error="loadError" />
      </section>
      <section class="modal-card-body" v-else-if="event">
        <status-message :record="event" @click="statusSet('pending')" />

        <div class="field">
          <label class="label">{{ $t('event_account') }}</label>
          <div class="control" v-if="!allAccounts">
            <sqr-input-display
              placeholder="event_account"
              :value="event.accountId"
              @click="accountPick = !disabled"
              :disabled="disabled"
            >
              <span class="mr-1">{{ event.givenName }}</span>
              <span class="font-medium">{{ event.familyName }}</span>
            </sqr-input-display>
            <account-picker
              v-if="accountPick"
              :disabled="accountDisabled"
              :invalid="$v.event.accountId.$error"
              :value="event.accountId"
              @select="accountSelect"
              @cancel="accountPick = false"
            />
            <div class="help is-danger" v-if="$v.event.accountId.$error">
              {{ $t('validation_required') }}
            </div>
          </div>
          <div class="control" v-if="!eid">
            <sqr-input-checkbox
              :disabled="accountDisabled"
              v-model="allAccounts"
              placeholder="event_modal_all_accounts_create_description"
            />
          </div>
        </div>

        <div class="field columns" style="margin-bottom: 0">
          <div class="column">
            <div class="field">
              <sqr-input-date
                :disabled="disabled"
                :invalid="$v.event.startDate.$error"
                :value="event.startDate"
                @change="fieldSetGen({ field: 'startDate', value: $event })"
                label="event_start_date"
              />
              <div class="help is-danger" v-if="$v.event.startDate.$error">
                {{ $t('validation_required') }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <sqr-input-date
                :disabled="disabled"
                :value="event.endDate"
                @change="fieldSetGen({ field: 'endDate', value: $event })"
                label="event_end_date"
              />
            </div>
          </div>
        </div>

        <div class="field">
          <sqr-input-display
            label="event_entry"
            :value="event.entryModelId"
            :disabled="disabled"
            :invalid="$v.event.entryModelId.$error"
            @click="entryModelPick = !disabled"
          >
            <span class="flex items-center" v-if="entry">
              <div class="w-2 h-6 mr-2" :style="entryStyle"></div>
              <div class="icon mr-2">
                <fa :icon="['fal', entry.icon]" />
              </div>
              <div>{{ entry | name }}</div>
            </span>
          </sqr-input-display>
          <entry-model-modal
            v-if="entryModelPick"
            label="event_entry_pick"
            @select="fieldSetGen({ field: 'entryModelId', value: $event.id })"
            @close="entryModelPick = false"
          />
          <div class="help is-danger" v-if="$v.event.entryModelId.$error">
            {{ $t('validation_required') }}
          </div>
        </div>

        <details class="border p-2 rounded my-4">
          <div class="field">
            <label class="label">{{ $t('event_weekday_exception') }}</label>
            <div class="columns is-gapless is-mobile">
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="1"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_mon') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="2"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_tue') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="3"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_wed') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="4"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_thu') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="5"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_fri') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="6"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_sat') }}</label
                >
              </div>
              <div class="column">
                <label class="checkbox"
                  ><input
                    :disabled="disabled"
                    :value="7"
                    type="checkbox"
                    v-model="weekDayExceptions"
                  />
                  {{ $t('event_weekday_exception_sun') }}</label
                >
              </div>
            </div>
          </div>

          <div class="py-8">
            <div class="field" v-for="entry in event.entries" :key="entry.date">
              <label class="label">
                <span class="mr-1">{{ entry.date | weekDayLong }}</span>
                <span class="has-text-weight-normal"
                  >({{ entry.date | week }})</span
                >
              </label>
              <div class="card" v-if="entry">
                <week-day-entry
                  :can-remove="false"
                  :entry="entry"
                  @change="entryChange($event)"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
        </details>

        <div class="field">
          <sqr-fire-files
            icon="upload"
            label="event_attachments"
            placeholder="event_attachments_upload"
            :value="event.attachments"
            :prefix="attachmentsPrefix"
            @change="fieldSet({ field: 'attachments', value: $event })"
            color="link"
            :disabled="disabled"
          />
        </div>

        <div class="field">
          <sqr-input-textarea
            :disabled="disabled"
            :value="event.note"
            @change="fieldSet({ field: 'note', value: $event })"
            label="event_note"
          />
        </div>

        <!-- <div class="field" v-if="isManagerRW">
          <label class="label">{{ $t('event_status') }}</label>
          <status-buttons :status="event.status" :disabled="disabled" @change="statusSet($event)" />
        </div> -->
      </section>
      <footer
        v-if="event && isManagerRW"
        class="modal-card-footer border-t bg-gray-100 px-8"
        style="padding-top: 5px; padding-bottom: 5px"
      >
        <div class="flex items-baseline w-full justify-center">
          <label class="label mr-4">{{ $t('event_status') }}</label>
          <status-buttons
            :status="event.status"
            :disabled="disabled"
            @change="statusSet($event)"
          />
        </div>
      </footer>
      <footer
        class="modal-card-foot"
        v-if="!loading"
        style="padding-top: 5px; padding-bottom: 5px; justify-content: flex-end"
      >
        <div class="control" v-if="event && event.commonId">
          <sqr-button
            :disabled="disabled"
            :is-loading="removing"
            @click="removeAllConfirm()"
            color="danger"
            label="event_remove_all"
          />
        </div>
        <div class="control" v-if="event && eid" style="flex-grow: 1">
          <sqr-button
            :disabled="disabled"
            :is-loading="removing"
            @click="remove"
            color="danger"
            label="event_remove"
          />
        </div>
        <div class="control">
          <sqr-button
            @click="unsub({ reset: true })"
            color="light"
            label="cancel"
            size="medium"
          />
        </div>
        <div class="control" v-if="!allAccounts">
          <sqr-button
            :disabled="disabled"
            :is-loading="saving"
            @click="validateAndSave()"
            color="primary"
            label="event_save"
            size="medium"
          />
        </div>
        <div class="control" v-if="allAccounts">
          <sqr-button
            :disabled="disabled"
            :is-loading="saving"
            @click="validateAndCreateAll()"
            color="primary"
            label="event_modal_all_accounts_create"
          />
        </div>
      </footer>
    </div>
  </div>
</template>

<style scoped>
.modal-card .modal-card-foot .control {
  margin-left: 0.6rem;
}
</style>

<script>
import event from '../views/event';
import { mapState } from 'vuex';
export default {
  name: 'EventModal',
  mixins: [event],
  computed: {
    ...mapState('event', { eid: 'id' }),
  },
};
</script>
