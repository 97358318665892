<template>
  <div class="field" v-if="record && record.status !== 'pending'">
    <div class="message is-warning is-clearfix">
      <div class="message-body is-clearfix">
        <div class="content">
          {{ $t('status_locked') }}
        </div>
        <div class="buttons">
          <sqr-button
            label="status_unlocked"
            color="warning"
            @click="$emit('click')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SqrButton from '@/sqrd/SqrButton';
export default {
  name: 'StatusMessage',
  components: { SqrButton },
  props: {
    record: Object,
  },
};
</script>
