import { render, staticRenderFns } from "./EventModal.vue?vue&type=template&id=32bdf929&scoped=true&"
import script from "./EventModal.vue?vue&type=script&lang=js&"
export * from "./EventModal.vue?vue&type=script&lang=js&"
import style0 from "./EventModal.vue?vue&type=style&index=0&id=32bdf929&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32bdf929",
  null
  
)

export default component.exports