export default function (string) {
  if (!string) return '#000000';
  let color = parseInt(string.substring(1), 16);
  let comp = {
    r: (color & 0xff0000) >> 16,
    g: (color & 0x00ff00) >> 8,
    b: color & 0x0000ff,
  };
  let avg = (comp.r + comp.g + comp.b) / 3;
  return avg > 128 ? '#000000' : '#ffffff';
}
