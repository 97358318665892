<template>
  <div>
    <label :class="labelClass" class="label" v-if="labelTr">{{
      labelTr
    }}</label>
    <div :class="controlClass" class="control">
      <label class="checkbox">
        <input
          :aria-describedby="nameDesc"
          :autocomplete="autocomplete"
          :class="inputClass"
          :disabled="disabled"
          :id="id"
          :name="name"
          :value="value"
          @change="change"
          type="checkbox"
          v-on="listeners"
        />
        {{ placeholderTr }}
      </label>
      <span class="icon is-small is-left" v-if="icon"
        ><i :class="iconClass" class="fa"></i
      ></span>
      <span class="icon is-small is-right" v-if="valid"
        ><i class="fal fa-check"></i
      ></span>
    </div>
    <p class="help" v-if="helper">{{ helperTr }}</p>
  </div>
</template>

<script>
import input from './mixins/input';

export default {
  name: 'sqr-input-checkbox',
  mixins: [input],
  props: {
    value: Boolean,
  },
  methods: {
    change(event) {
      this.$emit('input', event.target.checked);
      this.$emit('change', event.target.checked);
    },
  },
};
</script>
