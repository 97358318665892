let DateTime = require('luxon').DateTime;

module.exports = function (startDate, endDate, entriesOld, entriesNew) {
  let entries = [];
  let current = DateTime.fromISO(startDate);
  let end = DateTime.fromISO(endDate || startDate);

  while (current <= end) {
    const date = current.toISODate();
    const entryNew = entriesNew.find(e => e.date === date);
    if (entryNew) {
      let entry = entryNew;
      const entryOld = entriesOld.find(e => e.date === date);
      if (entryOld) {
        if (entryOld.input === entryNew.input) { // TODO better merge for input
          entry = JSON.parse(JSON.stringify(entryOld));
          entry.op = entryNew.op;
        }
      }
      entries.push(entry);
    } // else we don't need to copy anything

    current = current.plus({day: 1});
  }

  return entries;
}