<template>
  <section class="hero is-warning is-medium" v-if="error">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">{{ titleTr }}</h1>
        <h2 class="subtitle">{{ error.message }}</h2>
        <details>
          <summary>{{ $t('error_details') }}</summary>
          <pre>{{ error.stack }}</pre>
        </details>
        <div class="buttons">
          <sqr-button
            label="error_retry"
            @click="$emit(retry)"
            color="black"
            is-outlined
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SqrButton from './SqrButton';
export default {
  name: 'SqrError',
  components: { SqrButton },
  props: {
    error: { type: [Error, Object] },
    titleRaw: String,
  },
  computed: {
    titleTr() {
      if (this.titleRaw) {
        return this.titleRaw;
      } else if (this.title) {
        return this.$t(this.title);
      } else {
        return this.$t('error_title');
      }
    },
  },
};
</script>
